import { Button, ButtonGroup } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../contexts/AuthContext'

const IndexMenu = ({ currentIndex = 'SP500', lg }) => {
  const authContext = useAuthContext()
  const isPremium =
    authContext.account &&
    authContext.account.plan === 'premium' &&
    !authContext.account.expired
  const navigate = useNavigate()

  const renderListItem = (i, indexName) => {
    if (isPremium || i === 'SP500') {
      return (
        <Button
          color={i === currentIndex ? 'primary' : 'inherit'}
          onClick={() =>
            navigate(
              currentIndex
                ? decodeURI(`/premium/${i}`)
                : decodeURI('/premium/SP500')
            )
          }
        >
          {indexName}
        </Button>
      )
    }

    return (
      <Button disabled>
        <span className="material-icons" style={{ fontSize: '0.8rem' }}>
          lock
        </span>
        {indexName}
      </Button>
    )
  }

  if (lg) {
    return (
      <ButtonGroup
        size="medium"
        disableElevation
        variant="contained"
        aria-label="Disabled elevation buttons"
      >
        {renderListItem('SP500', 'S&P 500')}
        {renderListItem('SPTSX', 'S&P TSX')}
        {renderListItem('SPASX', 'S&P ASX')}
        {renderListItem('FTSE100', 'FTSE 100')}
        {renderListItem('RUSSELL2000', 'Russell 2000')}
        {renderListItem('CHINA_ADR', 'China ADRs')}
        {renderListItem('HONG_KONG_OTC', 'Hong Kong ADRs OTC')}
        {renderListItem('PHILIPPINES_OTC', 'Philippines ADRs OTC')}
        {renderListItem('SINGAPORE_OTC', 'Singapore ADRs OTC')}
        {renderListItem('SWISS_OTC', 'Swiss ADRs OTC')}
        {renderListItem('DUTC_OTC', 'Dutch ADRs OTC')}
        {/* {renderListItem('BRAZIL_ADR', 'Brazil ADRs')} */}
      </ButtonGroup>
    )
  }

  return null
}

export default IndexMenu
